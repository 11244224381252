<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <app-title title="Estadisticas" subtitle="Estadisticas de ventas, produccion, visualizaciones, etc.">
        <template slot="button">
          <v-btn v-if="type !== 0" fab dark small color="primary" @click="type = 0">
            <v-icon dark>chevron_left</v-icon>
          </v-btn>
        </template>
      </app-title>
      <v-layout row wrap>
        <!-- Productos -->
        <template v-if="id === 'productos'">
          <v-flex xs12>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="filterStatus" multiple>
                  <v-btn flat>
                    Activos
                  </v-btn>
                  <v-btn flat>
                    Inactivos
                  </v-btn>
                </v-btn-toggle>
                <v-btn v-if="rows.length > 0" flat icon @click="exportExcel">
                  <v-icon>save_alt</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-data-table
                :headers="headersProductsPrice"
                :items="rows"
                :loading="loading"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">
                    <router-link target="_blank" :to="{ name: 'product', params: { id: props.item.id } }">
                      <v-img
                        :src="props.item.img.url_thumb"
                        :lazy-src="`https://picsum.photos/10/6`"
                        class="lighten-2 mb-2"
                        width="60"
                      />
                    </router-link>
                  </td>
                  <td class="text-xs-left" v-html="props.item.name"></td>
                  <td :class="[`text-xs-right`]">
                    <v-chip label :color="props.item.status ? 'success' : 'error'" text-color="white" small>
                      {{ props.item.normalPrice | toPrice }}
                    </v-chip>
                  </td>
                  <td :class="[`text-xs-right`]">
                    <v-chip label :color="props.item.highPriceActive ? 'success' : 'error'" text-color="white" small>
                      {{ props.item.highPrice | toPrice }}
                    </v-chip>
                  </td>
                  <td class="text-xs-right">
                    <v-chip
                      label
                      :color="colorProductsVariation(props.item.variacion)"
                      :text-color="colorProductsVariationText(props.item.variacion)"
                    >
                      {{ props.item.variacion }}%
                      <v-icon v-if="props.item.variacion <= 0">arrow_downward</v-icon>
                    </v-chip>
                  </td>
                  <td class="text-xs-right">
                    {{ minutesSecons(props.item.time) }}
                  </td>
                  <td class="text-xs-right">
                    <v-btn
                      :to="{ name: 'product', params: { id: props.item.id } }"
                      flat
                      icon
                      small
                      target="_blank"
                      class="mx-0"
                    >
                      <v-icon small>desktop_windows</v-icon>
                    </v-btn>
                    <v-btn target="_blank" :href="props.item.urlWeb" flat icon small class="mx-0">
                      <v-icon small>web</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <!-- Pedidos Entregados -->
        <template v-else-if="id === 'pedidos-entregados'">
          <v-flex xs3>
            <v-select
              v-model="dateUse"
              :items="[{ id: 'fecha', name: 'Fecha de compra' }, { id: 'fecha_entrega', name: 'Fecha de entrega' }]"
              item-text="name"
              item-value="id"
              label="Fecha a usar"
              hide-details
            >
            </v-select>
          </v-flex>
          <v-flex xs3>
            <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-btn class="mt-2" color="success" block @click="getProductsPrice">
              Filtrar
            </v-btn>
          </v-flex>
          <v-flex xs6>
            <v-card class="mb-0">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ montoTotal | toPrice }}
                    </h2>
                    <h3 class="subheading">Monto Total</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs6>
            <v-card class="mb-0">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="rows.length"></h2>
                    <h3 class="subheading">Total de pedidos</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card class="mb-1">
              <v-data-table
                :headers="[
                  { text: 'Forma de pago', align: 'left', sortable: false },
                  { text: 'Total Pedidos', align: 'left', value: 'total_pedidos', sortable: true },
                  { text: 'Total', align: 'right', value: 'total', sortable: true },
                  { text: '', sortable: false }
                ]"
                :items="groupRows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">{{ props.item.payment.name }}</td>
                  <td class="text-xs-left" v-html="props.item.total_pedidos"></td>
                  <td class="text-xs-right">
                    {{ props.item.total | toPrice }}
                  </td>
                  <td class="text-xs-left">
                    <v-btn flat small color="success" @click="filterPriceProducts(props.item.payment.id)">
                      Filtrar
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar pedidos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar pedidos </span>
            </a>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Cliente', align: 'left', sortable: false },
                  { text: 'Total', align: 'right', sortable: false },
                  { text: 'Comuna', sortable: false },
                  { text: 'Horario de entrega', sortable: false }
                ]"
                :items="rows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">{{ props.item.id }}</td>
                  <td class="text-xs-left" v-html="props.item.name"></td>
                  <td class="text-xs-right">
                    {{ props.item.valor | toPrice }}
                  </td>
                  <td class="text-xs-left" v-html="props.item.comuna"></td>
                  <td class="text-xs-left" v-html="props.item.horario"></td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <!-- Repartidores -->
        <template v-else-if="id === 'repartidores'">
          <v-flex xs3>
            <v-select
              v-model="dealer"
              :items="dealers"
              item-text="name"
              item-value="id"
              label="Repartidor"
              hide-details
            >
            </v-select>
          </v-flex>
          <v-flex xs3>
            <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-btn class="mt-2" color="success" block @click="getDealerPrices">
              Filtrar
            </v-btn>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 v-if="tiempoPromedio > 0" class="display-3" style="font-weight: 300;">
                      {{ $moment.duration(tiempoPromedio, 'seconds').minutes() }}:{{
                        $moment.duration(tiempoPromedio, 'seconds').seconds()
                      }}
                    </h2>
                    <h2 v-else class="display-3" style="font-weight: 300;">
                      0
                    </h2>
                    <h3 class="subheading" style="font-size: 13px !important;">
                      Tiempo promedio entrega (minutos)
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar pedidos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar pedidos </span>
            </a>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ montoTotal | toPrice }}
                    </h2>
                    <h3 class="subheading">Total de despachos</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-1">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="rows.length"></h2>
                    <h3 class="subheading">Entregas realizadas</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Despacho', align: 'right', sortable: false },
                  { text: 'Comuna', sortable: false },
                  { text: 'Tiempo Entrega', sortable: false },
                  { text: 'Horario de entrega', sortable: false }
                ]"
                :items="rows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">{{ props.item.id }}</td>
                  <td class="text-xs-right">
                    {{ props.item.valor_despacho | toPrice }}
                  </td>
                  <td class="text-xs-left" v-html="props.item.comuna"></td>
                  <td class="text-xs-center">
                    {{ $moment.duration(props.item.diff, 'seconds').minutes() }}:{{
                      $moment.duration(props.item.diff, 'seconds').seconds()
                    }}
                    min
                  </td>
                  <td class="text-xs-left" v-html="props.item.horario"></td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <!-- Diseñadores -->
        <template v-else-if="id === 'disenadores'">
          <v-flex xs3>
            <v-select
              v-model="designer"
              :items="designers"
              item-text="name"
              item-value="id"
              label="Diseñadores"
              hide-details
            >
            </v-select>
          </v-flex>
          <v-flex xs3>
            <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-btn class="mt-2" color="success" block @click="getDesignerPrices">
              Filtrar
            </v-btn>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ finalizados }}
                    </h2>
                    <h3 class="subheading" style="font-size: 13px !important;">
                      Productos elaborados
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar pedidos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar pedidos </span>
            </a>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ finalizadosMonto | toPrice }}
                    </h2>
                    <h3 class="subheading">
                      Valor total
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-1">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="porFinalizar"></h2>
                    <h3 class="subheading">
                      Productos pendientes de finalizar
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Producto', align: 'left', sortable: false },
                  { text: 'Cantidad', align: 'right', sortable: false },
                  { text: 'valor Total', align: 'right', sortable: false },
                  { text: 'Fecha de entrega', sortable: false }
                ]"
                :items="rowsAll"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'orderIndex',
                        params: { id: props.item.id }
                      }"
                    >
                      {{ props.item.id }}
                    </router-link>
                  </td>
                  <td class="text-xs-left">{{ props.item.product.name }}</td>
                  <td class="text-xs-right">{{ props.item.product.cantidad }}</td>
                  <td class="text-xs-right">{{ props.item.product.valor_total_pedido | toPrice }}</td>
                  <td class="text-xs-left">
                    {{ props.item.date_in }}
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <!-- Horarios de entrega -->
        <template v-else-if="id === 'horarios-de-entrega'">
          <v-flex xs4>
            <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-btn class="mt-2" color="success" block @click="getProductsPrice">
              Filtrar
            </v-btn>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3" @click="filterOrder(1)">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ retrasos }}
                    </h2>
                    <h3 class="subheading">
                      Pedido con retraso
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar pedidos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar pedidos </span>
            </a>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3" @click="filterOrder(2)">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ perfectos }}
                    </h2>
                    <h3 class="subheading">Pedidos entregados en horario</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-1" @click="filterOrder(3)">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="rowsAll.length" />
                    <h3 class="subheading">Total</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Despacho', align: 'right', sortable: false },
                  { text: 'Comuna', sortable: false },
                  { text: 'Horario de entrega', sortable: false },
                  { text: 'Hora de entrega', sortable: false }
                ]"
                :items="rows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">{{ props.item.id }}</td>
                  <td class="text-xs-right">
                    {{ props.item.valor_despacho | toPrice }}
                  </td>
                  <td class="text-xs-left" v-html="props.item.comuna"></td>
                  <td class="text-xs-left" v-html="props.item.horario"></td>
                  <td>
                    {{ $moment(props.item.hora_entrega).format('HH:mm:ss') }}
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <!-- Cupones -->
        <template v-else-if="id === 'cupones'">
          <v-flex xs4>
            <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-btn class="mt-2" color="success" block @click="filterCupons">
              Filtrar
            </v-btn>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-0">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ promedioCupones | toPrice }}
                    </h2>
                    <h3 class="subheading">Media de descuento</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-0">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="rows.length"></h2>
                    <h3 class="subheading">Cupones usados</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-0">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="rows2.length"></h2>
                    <h3 class="subheading">Cupones enviados</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar cupones usados </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar cupones usados </span>
            </a>
            <a class="mx-1" @click="visible2 = !visible2">
              <span v-if="!visible2"> <v-icon small>expand_more</v-icon> Mostrar cupones enviados </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar cupones enviados </span>
            </a>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Cupon', sortable: false },
                  { text: 'Fecha de entrega', align: 'left', sortable: false },
                  { text: 'Valor del cupon', align: 'right', sortable: false }
                ]"
                :items="rows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">
                    <v-btn
                      depressed
                      color="accent"
                      target="_blank"
                      :to="{
                        name: 'orderProducts',
                        params: { id: props.item.idpedido }
                      }"
                      small
                    >
                      {{ props.item.idpedido }}
                    </v-btn>
                  </td>
                  <td class="text-xs-left" v-html="props.item.cupon"></td>
                  <td class="text-xs-left" v-html="props.item.date_shipping"></td>
                  <td class="text-xs-right">
                    {{ props.item.valor_total | toPrice }}
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex v-if="visible2" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Tipo', align: 'left' },
                  { text: 'Cupon', align: 'left' },
                  { text: 'Usuario', align: 'left' },
                  { text: 'Phone', align: 'right' },
                  { text: 'Email', align: 'left' }
                ]"
                :items="rows2"
                :loading="loading"
                class="elevation-2"
                no-data-text="No hay cupones enviadas con las fechas ingresadas"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
              >
                <v-progress-linear v-if="loading" slot="progress" color="primary" indeterminate> </v-progress-linear>
                <template slot="headers" slot-scope="props">
                  <tr>
                    <th v-for="header in props.headers" :key="header.text" :class="`text-xs-${header.align}`">
                      {{ header.text }}
                    </th>
                  </tr>
                </template>
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-center">
                      {{ props.item.type_send }}
                    </td>
                    <td class="text-xs-left">
                      <router-link target="_blank" :to="`/cupones-de-descuento/${props.item.id_cupon}`">
                        {{ props.item.cupon }}
                      </router-link>
                    </td>
                    <td class="text-xs-left">
                      <router-link target="_blank" :to="`/cliente/${props.item.id_user}`">
                        {{ props.item.name }}
                      </router-link>
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.phone }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.email }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <template v-else-if="id === 3">
          <v-flex xs12 sm6 md4>
            <v-card class="mb-3">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-2" style="font-weight: 300;">
                      {{ montoTotal | toPrice }}
                    </h2>
                    <h3 class="subheading">Ganancias mes {{ this.$moment().format('MMMM') }}</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar pedidos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar pedidos </span>
            </a>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-card class="mb-3">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-2" style="font-weight: 300;">
                      {{ descuentoTotal | toPrice }}
                    </h2>
                    <h3 class="subheading">Descuentos mes Actual</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <a class="mx-1" @click="visibleDiscount = !visibleDiscount">
              <span v-if="!visibleDiscount"> <v-icon small>expand_more</v-icon> Mostrar descuentos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar descuentos </span>
            </a>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-card class="mb-1">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-2" style="font-weight: 300;" v-html="rows.length"></h2>
                    <h3 class="subheading">Total de pedidos</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-card-title class="title">Pedidos entregados mes actual</v-card-title>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Cliente', align: 'left', sortable: false },
                  { text: 'Total', align: 'right', sortable: false },
                  { text: 'Comuna', sortable: false },
                  { text: 'Horario de entrega', sortable: false }
                ]"
                :items="rows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">
                    <v-btn
                      depressed
                      color="accent"
                      target="_blank"
                      :to="{
                        name: 'orderIndex',
                        params: { id: props.item.id }
                      }"
                      small
                    >
                      {{ props.item.id }}
                    </v-btn>
                  </td>
                  <td class="text-xs-left" v-html="props.item.name"></td>
                  <td class="text-xs-right">
                    {{ props.item.valor | toPrice }}
                  </td>
                  <td class="text-xs-left" v-html="props.item.comuna"></td>
                  <td class="text-xs-left" v-html="props.item.horario"></td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex v-if="visibleDiscount" xs12>
            <v-card>
              <v-card-title class="title">Listados de descuentos</v-card-title>
              <v-data-table
                :headers="[
                  { text: 'Solicitud', align: 'left', sortable: false },
                  { text: 'Nombre', align: 'left', sortable: false },
                  { text: 'Cantidad', align: 'right', sortable: false },
                  { text: 'Precio', align: 'right', sortable: false },
                  { text: 'Total', align: 'right', sortable: false }
                ]"
                :items="discounts"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left"># {{ props.item.solicitud }}</td>
                  <td class="text-xs-left" v-html="props.item.name"></td>
                  <td class="text-xs-right">{{ props.item.quantity }}</td>
                  <td class="text-xs-right">
                    {{ props.item.price | toPrice }}
                  </td>
                  <td class="text-xs-right">
                    {{ props.item.value | toPrice }}
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <template v-else-if="id === 7">
          <v-flex xs4>
            <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-btn class="mt-2" color="success" block @click="getProductsPrice">
              Filtrar
            </v-btn>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3" @click="filterOrder(1)">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ retrasos }}
                    </h2>
                    <h3 class="subheading">
                      Pedido con retraso
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <a class="mx-1" @click="visible = !visible">
              <span v-if="!visible"> <v-icon small>expand_more</v-icon> Mostrar pedidos </span>
              <span v-else> <v-icon small>expand_less</v-icon> Ocultar pedidos </span>
            </a>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-3" @click="filterOrder(2)">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;">
                      {{ perfectos }}
                    </h2>
                    <h3 class="subheading">Pedidos entregados en horario</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs4>
            <v-card class="mb-1" @click="filterOrder(3)">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 text-xs-right>
                    <h2 class="display-3" style="font-weight: 300;" v-html="rowsAll.length" />
                    <h3 class="subheading">Total</h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="visible" xs12>
            <v-card>
              <v-data-table
                :headers="[
                  { text: 'Pedido', align: 'left', sortable: false },
                  { text: 'Despacho', align: 'right', sortable: false },
                  { text: 'Comuna', sortable: false },
                  { text: 'Horario de entrega', sortable: false },
                  { text: 'Hora de entrega', sortable: false }
                ]"
                :items="rows"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">{{ props.item.id }}</td>
                  <td class="text-xs-right">
                    {{ props.item.valor_despacho | toPrice }}
                  </td>
                  <td class="text-xs-left" v-html="props.item.comuna"></td>
                  <td class="text-xs-left" v-html="props.item.horario"></td>
                  <td>
                    {{ $moment(props.item.hora_entrega).format('HH:mm:ss') }}
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </template>
        <template v-else> </template>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import qs from 'qs'
import appTitle from '../../useful/title.vue'
import { MI_STORE, GET_DATE_IMPORTANT } from '../../../config'

export default {
  name: 'StoreStatistics',
  components: { appTitle },
  props: ['id'],
  data() {
    return {
      filterStatus: [],
      dealers: [],
      designers: [],
      dealer: {},
      designer: {},
      dateStart: this.$moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      type: 0,
      items: [{ id: 1, title: 'Productos - Variacion precios' }],
      headersProductsPrice: [
        { text: '', align: 'left', sortable: false },
        { text: 'Nombre', align: 'left', sortable: false },
        { text: 'Normal', value: 'normalPrice', sortable: true },
        { text: 'Alta Demanda', value: 'highPrice', sortable: true },
        { text: 'Variacion', value: 'variacion', sortable: true },
        { text: 'Tiempo', value: 'time', sortable: true },
        { text: '', sortable: false }
      ],
      rows: [],
      rows2: [],
      rowsActive: [],
      rowsInactive: [],
      rowsAll: [],
      discounts: [],
      pagination: {
        sortBy: 'variacion',
        rowsPerPage: 50
      },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      totalRow: 0,
      visible: false,
      visible2: false,
      visibleDiscount: false,
      groupRows: [],
      dateUse: 'fecha_entrega'
    }
  },
  computed: {
    promedioCupones() {
      if (this.id === 'cupones') {
        if (this.rows.length > 0)
          return this.rows.reduce((partialSum, a) => partialSum + a.valor_total, 0) / this.rows.length
      }
      return 0
    },
    retrasos() {
      if (this.id === 'horarios-de-entrega') {
        return this._.filter(this.rowsAll, ['retraso', true]).length
      }
      return 0
    },
    perfectos() {
      if (this.id === 'horarios-de-entrega') {
        return this._.filter(this.rowsAll, ['retraso', false]).length
      }
      return 0
    },
    tiempoPromedio() {
      if (this.id === 'repartidores') {
        return this._.sumBy(this.rows, 'diff') / this.rows.length
      }
      return 0
    },
    montoTotal() {
      if (this.id === 'repartidores') {
        return this._.sumBy(this.rows, 'valor_despacho')
      }
      return this._.sumBy(this.rows, 'valor')
    },
    descuentoTotal() {
      return this._.sumBy(this.discounts, 'value')
    },
    porFinalizar() {
      const sum = this._.sumBy(this.rowsInactive, e => {
        return e.product.cantidad
      })
      return sum
    },
    finalizados() {
      const sum = this._.sumBy(this.rowsActive, e => {
        return e.product.cantidad
      })
      return sum
    },
    finalizadosMonto() {
      const sum = this._.sumBy(this.rowsActive, e => {
        return e.product.cantidad * e.product.valor_total_pedido
      })
      return sum
    }
  },
  watch: {
    filterStatus(e) {
      this.filterItems(e)
    }
  },
  mounted() {
    this.rows = []
    const { id } = this
    if (id !== 'repartidores') {
      this.getProductsPrice()
    }
    if (id === 'repartidores') {
      this.getDealers()
    }
    if (id === 'disenadores') {
      this.getDesigner()
    }
    if (id === 'cupones') {
      this.getOrderCupons()
      this.getStaticsDateImportants()
    }
  },
  methods: {
    filterCupons() {
      this.getOrderCupons()
      this.getStaticsDateImportants()
    },
    async getStaticsDateImportants() {
      this.loading = true
      try {
        const filter = {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          type: 1
        }
        const res = await this.$http.get(`${GET_DATE_IMPORTANT}?${qs.stringify({ filters: filter })}`)
        this.rows2 = res.data
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async getCupones() {
      this.loading = true
      try {
        const res = await this.$http(
          `${MI_STORE}/statistics/cupones?${this.$qs.stringify({
            type: this.type,
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              dateUse: this.dateUse
            }
          })}`
        )
        if (this.type === 1) {
          this.loading = false
          this.rows = res.data
          this.rowsAll = res.data
        } else if (this.type === 2) {
          this.rowsAll = res.data.orders
          this.rows = res.data.orders
          this.groupRows = res.data.group
        } else if (this.type === 3) {
          this.rows = res.data.orders
          this.discounts = res.data.discount
        } else if (this.type === 5) {
          this.rows = res.data.orders
          this.rowsAll = res.data.orders
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getOrderCupons() {
      this.loading = true
      try {
        const res = await this.$http(
          `${MI_STORE}/statistics/cupones?${this.$qs.stringify({
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              dateUse: this.dateUse
            }
          })}`
        )
        this.rowsAll = res.data.orders
        this.rows = res.data.orders
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getProductsPrice() {
      const { id } = this
      this.loading = true
      let type = 0
      if (id === 'productos') {
        type = 1
      }
      if (id === 'pedidos-entregados') {
        type = 2
      }
      if (id === 'disenadores') {
        type = 6
      }
      if (id === 'horarios-de-entrega') {
        type = 5
      }
      if (id === 'cupones') {
        type = 8
      }
      try {
        const res = await this.$http(
          `${MI_STORE}/statistics/price?${this.$qs.stringify({
            type,
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              dateUse: this.dateUse
            }
          })}`
        )
        if (type === 1) {
          this.loading = false
          this.rows = res.data
          this.rowsAll = res.data
        } else if (type === 2) {
          this.rowsAll = res.data.orders
          this.rows = res.data.orders
          this.groupRows = res.data.group
        } else if (type === 3) {
          this.rows = res.data.orders
          this.discounts = res.data.discount
        } else if (type === 5) {
          this.rows = res.data.orders
          this.rowsAll = res.data.orders
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDealerPrices() {
      try {
        const res = await this.$http.get(
          `${MI_STORE}/statistics/dealer?${this.$qs.stringify({
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            },
            dealer: this.dealer
          })}`
        )
        this.rows = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDesignerPrices() {
      try {
        const res = await this.$http.get(
          `${MI_STORE}/statistics/designer?${this.$qs.stringify({
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            },
            designer: this.designer
          })}`
        )
        this.rowsActive = this._.filter(res.data, ['type', 1])
        this.rowsInactive = this._.filter(res.data, ['type', 0])
        this.rowsAll = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDealers() {
      try {
        const res = await this.$http(`${MI_STORE}/dealers`)
        this.dealers = this._.filter(res.data, ['status', true])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDesigner() {
      try {
        const res = await this.$http(`${MI_STORE}/designers`)
        this.designers = this._.filter(res.data, ['status', true])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    filterPriceProducts(e) {
      console.log(e)
      this.rows = this._.filter(this.rowsAll, ['forma_de_pago', e])
      this.visible = true
    },
    filterOrder(e) {
      if (this.type === 5 && e === 1) {
        this.rows = this._.filter(this.rowsAll, ['retraso', true])
      } else if (this.type === 5 && e === 2) {
        this.rows = this._.filter(this.rowsAll, ['retraso', false])
      } else if (this.type === 5 && e === 3) {
        this.rows = this.rowsAll
      }
    },
    filterItems(e) {
      const activo = this._.indexOf(e, 0)
      const inactivo = this._.indexOf(e, 1)
      this.rows = this._.filter(this.rowsAll, o => {
        if (activo >= 0 && inactivo < 0) {
          return o.status
        }
        if (inactivo >= 0 && activo < 0) {
          return !o.status
        }
        return true
      })
    },
    minutesSecons(seconds) {
      return this.$moment.utc(seconds * 1000).format('HH:mm:ss')
    },
    colorProductsVariation(value) {
      if (value <= 0) {
        return 'error'
      }
      return ''
    },
    colorProductsVariationText(value) {
      if (value <= 0) {
        return 'white'
      }
      return 'black'
    }
  }
}
</script>
